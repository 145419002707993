/* eslint-disable */
export default {
  hj1: '余额',
  hj2: '总收益',
  hj3: '链上余额',
  hj4: '查看奖励',
  hj5: '提現',
  hj6: '兑换',
  hj7: '全部',
  hj8: '活动奖励',
  hj9: '质押奖励',
  hj10: '提現记录',
  hj11: '暂无数据',
  hj12: '奖励金额',
  hj13: '提現金额',
  hj14: '帮助中心',
  hj15: '您将接受高达',
  hj16: '奖励',
  hj17: '规则',
  hj18: '结束',
  hj19: '奖励标准',
  hj20: '用户金额',
  hj21: '说明',
  hj22: '总奖励',
  hj23: '用户奖励',
  hj24: '参与活动',
  hj25: '领取',
  hj26: '质押金额',
  hj27: '确认',
  hj28: '取消',
  hj31: '质押',
  hj32: '开始质押',
  hj33: '现在前往',
  hj34: '我知道了',
  hj35: '收益率',
  hj36: '已质押额',
  hj37: '开始',
  hj38: '账户',
  hj39: '年化利率',
  hj40: '收益记录',
  hj41: '帮助中心',
  hj42: '介绍质押',
  hj43: '活动',
  hj44: '选择语言',
  hj45: '客服中心',
  hj46: '选择语言',
  hj47: '开始质押',
  hj48: '请输入质押金额',
  hj49: '质押',
  hj50: '参加成功',
  hj51: '確定',
  hj52: '发送',
  hj53: '复制',

  hj54: '鏈結碼',
  hj55: '複製邀請碼',
  hj56: '接受USDT链接码',
  hj57: '发送USDT至',
  hj58: '输入USDT数量',
  hj59: '最小需求金额',
  hj60: '確定参加',
  hj61: '活动锁定时间',
  hj62: '领取获利资金',
  hj63: '未到解锁时间',
  hj64: '活动奖励(USDT)已绑定',

  hj65: '本次活动奖励金额',
  hj66:"顯示網站網址"
};
