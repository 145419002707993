<template>
  <div class="main">
    <div class="header_ico" @click="closeShow">
      <img src="/ico/close.png" />
    </div>

    <div class="but_box" v-if="Nowsider == ''">
      <div class="left_bt" @click="changeNowSider('records')">
        <img src="/ico/sy.png" />{{ $t("hj40") }}
      </div>
      <!-- <div class="left_bt" @click="changeNowSider('help')">
        <img src="/ico/bz.png" />{{ $t("hj41") }}
      </div> -->
      <div class="left_bt" @click="changeNowSider('pledge')">
        <img src="/ico/js.png" />{{ $t("hj42") }}
      </div>
      <div class="left_bt" @click="changeNowSider('activity')">
        <img src="/ico/hd.png" />{{ $t("hj43") }}
      </div>
      <div class="left_bt" @click="changeNowSider('language')">
        <img src="/ico/yy.png" />{{ $t("hj44") }}
      </div>

      <div class="left_bt" @click="changeNowSider('hreafHome')">
        <img src="/ico/add.png" />{{ $t("hj54") }}
      </div>
      <div class="left_bt" @click="onCopy">
        <img src="/ico/fx.png" />{{ $t("hj55") }}
      </div>
      <div class="left_bt" @click="kfShow = true">
        <img src="/ico/kf.png" />聯絡我們
      </div>
    </div>

    <pledge v-if="Nowsider == 'pledge'" @siderif="siderShow"></pledge>
    <hreafHome v-if="Nowsider == 'hreafHome'"></hreafHome>
    <language v-if="Nowsider == 'language'"></language>
    <records v-if="Nowsider == 'records'"></records>
    <help v-if="Nowsider == 'help'"></help>
    <activity v-if="Nowsider == 'activity'"></activity>

    <div
      v-if="kfShow"
      style="
        position: absolute;
        height: 12rem;
        width: 90%;
        color: #fff;
        background: linear-gradient(180deg, #184355 0%, #0c2733 100%);
        padding: 1rem;
        border-radius: 10px;
        left: 5%;
        top: 5rem;
      "
    >
      <img
        @click="kfShow = false"
        src="/ico/close.png"
        style="width: 1rem; float: right; top: 2rem"
      />
      <div style="margin-top: 2rem">聯絡資訊</div>
      <div style="margin-top: 1rem">Email:{{ kf_url }}</div>
      <div style="text-align: center; margin-top: 2rem" @click="copyKf">
        复制
      </div>
    </div>
  </div>
</template>
<script>
import pledge from "./siderCom/pledge.vue";
import language from "../pages/language.vue";
import hreafHome from "../pages/hreafHome.vue";
import records from "../pages/records.vue";
import help from "../pages/help.vue";
import activity from "../pages/activity.vue";
import clickfunction from '../assets/js/main'
import { Toast } from 'vant-green';
import { invitecode, getKf } from '../http/api'
export default {
  data () {
    return {
      Nowsider: '',
      invitecodes: '',
      kf_url: '',
      kfShow: false
    }
  },
  components: {
    pledge,
    language,
    records,
    help,
    activity,
    hreafHome
  },
  created () {
    getKf().then((res) => {
      this.kf_url = res.data.kf_url
    })
  },
  methods: {
    showSider () {
      invitecode().then((res) => {
        this.invitecodes = res.data.invite_code
        console.log(this.invitecodes)
      })
    },
    onCopy () {
      const textArea = document.createElement('textArea')
      textArea.value = window.location.href;
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
      Toast({
        message: '已复制邀请码'
      });
    },
    closeShow () {
      this.$parent.closeShow();
    },
    changeNowSider (value) {
      console.log(value)
      if (value == 'records') {
        // alert(1)
        // if (window.localStorage.getItem('useReg') == 1) {
        this.Nowsider = value
        // } else {
        // clickfunction()
        // }
      } else {
        this.Nowsider = value
      }

    },
    siderShow (value) {
      this.Nowsider = value
    },
    toKf () {
      window.open(localStorage.getItem('kf_url'))
    },

  }
}
</script>

<style lang="scss" scoped>
.main {
  height: 95%;
  width: 100%;
  position: fixed;
  background: #122531;
  top: 5%;
  border-radius: 10px 10px 0 0;
  z-index: 999;
  .header_ico {
    img {
      width: 1rem;
      margin: 1rem;
    }
  }
  .but_box {
    .left_bt {
      width: calc(100% - 2rem);
      height: 2rem;
      border-radius: 10px;
      background: #252524;
      opacity: 0.6;
      margin-left: 1rem;
      margin-bottom: 5px;
      color: #fff;
      img {
        width: 1rem;
        height: 1rem;
        margin: 0.5rem 0.5rem 0 0.75rem;
      }
    }
  }
}
</style>