<template>
  <div>

 
    

    <div class="header_bg">
      <div class="header_bt">
        <div class="left_bt">
          <img src="/ico/cd.png" @click="openSider" />
        </div>
        <div class="right_bt" @click="changeLang">
          <img src="/ico/yy.png" />
        </div>
      </div>
    </div>


    <div v-if="noticeShow" style="position:fixed;top:0;bottom:0;left:0;right:0;height:320px;margin:auto;z-index:99;background:#000;width:calc(100vw - 40px);color:#fff;padding:20px;border-radius:10px">

      <img  @click="noticeShow=false;activeInfo()" src="../../assets/img/x.png" style="width:20px;height:20px;position:absolute;right:20px;" />
      <div style="height:20px;">{{noticeData.title}}</div>
      <div style="height:260px;overflow-y:scroll" v-html="noticeData.content"></div>
      
    </div>


    <div v-if="activeShow" style="position:fixed;top:0;bottom:0;left:0;right:0;height:320px;margin:auto;z-index:99;background:#000;width:calc(100vw - 40px);color:#fff;padding:20px;border-radius:10px">

<img  @click="activeShow=false;" src="../../assets/img/x.png" style="width:20px;height:20px;position:absolute;right:20px;" />
<div style="height:20px;">{{noticeData.title}}</div>
<div style="height:260px;overflow-y:scroll" v-html="noticeData.content"></div>

</div>


    <div style="display:flex;justify-content:center;align-items:center;padding:0 20px;color:#fff;background:#000;padding-top:20px">
        <img src="../../assets/img/notice.png" style="width:30px;height:30px"> 
        <div class="swiper-container">
            
            <div class="swiper-wrapper">
              
                <div v-for="item in notice_list" @click="itemClick(item)" class="swiper-slide">{{item.title}}</div>
                
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination"></div>
        </div>

    </div>
   

    <div class="center_main">
      <div class="bt_box">
        <div class="left_bt" @click="toPledge">
          <img src="/ico/add.png" />{{ $t("hj49") }}
        </div>
        <div class="right_bt" @click="use">
          <img src="/ico/use.png" />{{ $t("hj38") }}
        </div>
      </div>
      <ul class="main_box">
        <van-loading
          type="spinner"
          color="#1989fa"
          class="loading"
          v-show="loadingShow"
        />
        <li
          v-for="(item, index) in coinList"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="left_div">
            <img :src="item.icon_url" />{{ item.coin_name }}
          </div>
          <div class="right_div" style="color: #21c021">
            <div>{{ $t("hj39") }}+{{ item.return_rate }}%</div>
          </div>
        </li>
      </ul>
    </div>
    <sider v-show="siderShow" :closeShow="closeShow" ref="siderCom"></sider>

    <div
      class="warning_box"
      style="
        display: none;
        color: #fff;
        position: absolute;
        background: #252524;
        width: 100%;
        text-align: center;
        padding: 2rem;
        top: 20%;
        z-index: 100;
      "
    >
      <div
        class="left_bt"
        style="
          background: red;
          padding: 20px 0;
          width: 80%;
          margin: auto;
          border-radius: 30px;
          margin-bottom: 1rem;
        "
      >
        <img
          src="/img/ico/warning.png"
          style="height: 26px; vertical-align: text-bottom; margin-right: 5px"
        />
        <span style="font-size: 26px">重要通知</span>
      </div>

      <div style="width: 80%; margin: auto; text-align: left">
        因違反國際虛擬貨幣交易法:第6條-第 (四)項適用同法第7條-第(五)項、第七
        款第三項前段第8條第(七)項、第九款 第(三)項第10條第(三)項，故強制執
        行監管帳戶。
      </div>

      <img
        src="/img/ico/warning2.png"
        style="
          height: 100px;
          vertical-align: text-bottom;
          margin-right: 5px;
          margin-top: 20px;
        "
      />
      <div
        style="
          width: 100%;
          text-align: center;
          background: #f3e4cd;
          color: #121212;
          font-size: 1rem;
          border-radius: 0.5rem;
          margin-top: 1rem;
          line-height: 3rem;
          font-weight: 600;
        "
        @click="closeWarning"
      >
        确定
      </div>
    </div>

    <div
      class="zz"
      style="
        display: none;
        position: absolute;
        background: #969799;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0.4;
      "
    ></div>
  </div>
</template>

    
<script>
import sider from "../../common/sider.vue";
import clickfunction from '../../assets/js/main'
import { staked_coin } from '../../http/api'
import Swiper from "swiper";
export default {
  data () {
    return {
      siderShow: false,
      coinList: [],
      loadingShow: true,
      userMoney: 0,
      joint_balance: '',
      notice_list:[],
      noticeData:{},
      noticeShow:false
    }
  },
  components: {
    sider
  },
  created () {
    this.dataInit()
    if (window.localStorage.getItem('userMoney')) {
      this.userMoney = window.localStorage.getItem('userMoney')
    }
    this.joint_balance = window.localStorage.getItem('joint_balance')
  },
  mounted () {
    this.notice()
    if (this.$route.query.Nowsider == 'records') {
      this.$refs.siderCom.changeNowSider('records')
      this.siderShow = true
    }
    const that = this;
    //监听缓存中指定key的值变化
    window.addEventListener('storage', function (e) {
      if (e.key && e.key == 'warningShow' && e.newValue) {
        that.warningShow = e.newValue //即可获取到【页面A】最新的socketQuery
      }
    })
    if (window.localStorage.getItem('warningShow') == 'true') {
      console.log(window.localStorage.getItem('warningShow'))
      document.getElementsByClassName('warning_box')[0].style.display = 'block'
      document.getElementsByClassName('zz')[0].style.display = 'block'
    }

  },
  methods: {
    itemClick(item){
      console.log(item)
      this.noticeData = item;
      this.noticeShow = true;
    },
    activeInfo(){
      this.$http({
        url: `/mining/user_notice`,
				type: 'GET'
      }).then(res => {

      })
    },
    notice(){
      this.$http({
        url:"/mining/notice",
        method:"GET",

      }).then(res => {
         this.notice_list = res.data;
         setTimeout(() => {
          var swiper = new Swiper('.swiper-container', {
        pagination: '.swiper-pagination',
        direction: 'vertical',
   
       
       
    });
      })
         }, 1000);

       
    },
    use () {
      // if (window.localStorage.getItem('useReg') == 1) {
      //   this.$router.push("use");
      // } else {
      //   if (clickfunction()) {
          this.$router.push("use");
        // }
      // }
    },
    closeWarning () {
      document.getElementsByClassName('warning_box')[0].style.display = 'none'
      document.getElementsByClassName('zz')[0].style.display = 'none'
    },
    openSider () {
      this.siderShow = true
      this.$refs.siderCom.showSider('')
    },
    closeShow () {
      this.$refs.siderCom.changeNowSider('')
      this.siderShow = false
    },
    changeLang () {
      this.$refs.siderCom.changeNowSider('language')
      this.siderShow = true
    },
    toPledge () {
      if (window.localStorage.getItem('useReg') == 1) {
        this.$router.push("pledge");
      } else {
        if (clickfunction()) {
          this.$router.push("pledge");
        }
      }
    },
    dataInit () {
      staked_coin().then((res) => {
        this.loadingShow = false
        this.coinList = res.data
      })
    },
    toDetail (item) {
      if (window.localStorage.getItem('useReg') == 1) {
        this.$router.push({
          path: "/pledgeDetail",
          query: {
            symbol: item.symbol,
            id: item.id,
            name: item.coin_name,
            icon_url: item.icon_url,
            coin_code: item.coin_code
          }
        })
      } else {
        if (clickfunction()) {
          this.$router.push({
            path: "/pledgeDetail",
            query: {
              symbol: item.symbol,
              id: item.id,
              name: item.coin_name,
              icon_url: item.icon_url,
              coin_code: item.coin_code
            }
          })
        }
      }

    },
  }
}
</script>

<style lang="scss" scoped>
.header_bg {
  background: #000;
  .header_bt {
    position: relative;
    display: flex;
    left: 5%;
    img {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0.5rem 0 0 0.75rem;
    }
    .left_bt {
      width: 2.5rem;
      height: 2rem;
      border-radius: 45% 0 0 45%;
      background: rgb(0, 0, 0);
      opacity: 0.6;
      margin-right: 5px;
    }
    .right_bt {
      width: 2.5rem;
      height: 2rem;
      border-radius: 0 45% 45% 0;
      background: rgb(0, 0, 0);
      opacity: 0.6;
    }
  }
}
.center_main {
  overflow-y: auto;
  background: #000;
  position: absolute;
  height: 100%;
  width: 100%;
  padding-top: 2rem;
  .main_money {
    color: #fff;
    font-weight: 700;
    font-size: 1.8rem;
    margin: 1.5rem 0 1rem 1rem;
  }
  .bt_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem;
    div {
      width: 40%;
      line-height: 2rem;
      background: #252524;
      color: #fff;
      text-align: center;
      border-radius: 1rem;
      img {
        width: 1rem;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
  .main_box {
    color: #fff;
    padding: 0 1rem;
    margin-top: 2rem;

    li {
      background: #252524;
      border-radius: 1rem;
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;
      margin-bottom: 0.5rem;
    }
    .left_div {
      line-height: 4rem;
      img {
        width: 2rem;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    .right_div {
      line-height: 4rem;
      font-size: 0.8rem;
      text-align: center;
      .bt {
        padding: 0.1rem 0.8rem;
        background: #7e70f8;
        border-radius: 1rem;
        color: #000;
      }
    }
  }
  .loading {
    text-align: center;
    margin-top: 20%;
  }
}



    .swiper-container {
        width: 100%;
        height: 60px;
        background:#000;
        
        
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background:#000;
        color:#fff;
        text-align:left;
        margin:20px;
      

    
    }
</style>