import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http/axios';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import common from './common/function';
import 'swiper/swiper-bundle.css';
import store from './store';
import i18n from '@/locales';
import { Lazyload } from 'vant';



Vue.prototype._i18n = i18n;
Vue.prototype.$http = http;
Vue.prototype.common = common;
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper /* { default options with global component } */);
Vue.use(Vant);
Vue.use(Lazyload);
new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
