import Axios from 'axios';
// import Vue from 'vue';
import { Toast } from 'vant-green';
var userAddress;
if (localStorage.getItem('userAddress')) {
  userAddress = localStorage.getItem('userAddress');
} else {
  userAddress = '';
}

console.log("userAddress------",userAddress)
const axios = Axios.create({
  baseURL: 'https://web.aine-source.shop/api/',
  timeout: 1000 * 180,
  headers: {
    'Content-Type': 'application/json',
    // 'Accept-Language': window.localStorage.getItem('language'),
    // address: userAddress
  },
  defaults: {
    withCredentials: true
  }
});

axios.interceptors.request.use(
  config => {
    config.headers['Accept-Language'] = window.localStorage.getItem('language')
      ? window.localStorage.getItem('language')
      : 'Fn';

      // config.headers['address']  =  "0x5F867AD623a5Be16ADbF8C32245B3546f9dE9839"

      config.headers['address'] =window.localStorage.getItem('userAddress')
      ? window.localStorage.getItem('userAddress')
      : '123';;
     
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
// 响应拦截器
axios.interceptors.response.use(
  res => {
    if (res.data.size) {
      return Promise.resolve(res);
    } else if (res.data.code !== 1 && res.data.code !== 200) {
      Toast({
        message: res.data.msg
      });
    }
    return Promise.resolve(res.data);
  },
  error => {
    Toast({
      message: '服务器繁忙，请稍后再试'
    });
    return Promise.reject(error);
  }
);
export default axios;
